import CollapsibleText_DefaultComponent from '../components/CollapsibleText/viewer/skinComps/Default/Default.skin';
import CollapsibleText_DefaultController from '../components/CollapsibleText/viewer/CollapsibleText.controller';


const CollapsibleText_Default = {
  component: CollapsibleText_DefaultComponent,
  controller: CollapsibleText_DefaultController
};


export const components = {
  ['CollapsibleText_Default']: CollapsibleText_Default
};

